import { css } from 'styled-components';
import {
  CssContentType,
  MediaType,
  ThemeBreakpointInterface,
} from './theme-interface';

export const mediaQueryCssFrom =
  (start: ThemeBreakpointInterface, mediaType: MediaType = 'screen') =>
  (content: CssContentType) => css`
    @media ${mediaType} and (min-width: ${start.minWidth}px) {
      ${content}
    }
  `;

export const mediaQueryCssUpTo =
  (end: ThemeBreakpointInterface, mediaType: MediaType = 'screen') =>
  (content: CssContentType) => css`
    @media ${mediaType} and (max-width: ${end.minWidth}px) {
      ${content}
    }
  `;

export const mediaQueryCssBetween =
  (
    start: ThemeBreakpointInterface,
    end: ThemeBreakpointInterface,
    mediaType: MediaType = 'screen',
  ) =>
  (content: CssContentType) => css`
    @media ${mediaType} and (min-width: ${start.minWidth}px) and (max-width: ${end.minWidth}px) {
      ${content}
    }
  `;

export const BaSeMediaQueries = {
  mediaQueryCssFrom,
  mediaQueryCssUpTo,
  mediaQueryCssBetween,
};
